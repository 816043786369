import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import { StaticImage } from 'gatsby-plugin-image'
import Spacing from '../../components/partials/Spacing'

import Layout from '../../components/Layout'

import monitor01 from '../../resources/images/usecases/monitor/01_healthy-platform.png'
import monitor02 from '../../resources/images/usecases/monitor/02_alerts.png'
import monitor03 from '../../resources/images/usecases/monitor/03_topology.png'
import monitor04 from '../../resources/images/usecases/monitor/04_kafka-topics.png'
import monitor05 from '../../resources/images/usecases/monitor/05_explore-schemas.png'

import dataImage from '../../resources/images/usecases/monitor/data.svg'
import application from '../../resources/images/usecases/monitor/application.svg'
import brokers from '../../resources/images/usecases/monitor/brokers.svg'
import business_service from '../../resources/images/usecases/monitor/business_service.svg'
import connect from '../../resources/images/usecases/monitor/connect.svg'
import flows from '../../resources/images/usecases/monitor/flows.svg'
import network from '../../resources/images/usecases/monitor/network.svg'
import schema_registry from '../../resources/images/usecases/monitor/schema_registry.svg'
import system from '../../resources/images/usecases/monitor/system.svg'
import zookeeper from '../../resources/images/usecases/monitor/zookeeper.svg'

import ModalImage from 'react-modal-image'

const kafkaMonitoring = () => {
    const data = useStaticQuery(graphql`
        query MonitorSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Usecases_Monitor" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className="home-fixed-top"
                src="../../resources/images/homepage/hero_lens.svg"
                placeholder="blurred"
                alt="Home Lenses"
            />
            <section className="pt-5 pb-5 mt-5 ">
                <div className="container-1 pb-5 ipad-margin-top">
                    <div className="d-flex flex-wrap mt-3">
                        <div className="col-md-6 pt-0">
                            <p className="title breadcrumb-text pt-4">
                                <a href="/usecases/" className="h4-like">
                                    Use cases
                                </a>{' '}
                                <span className="h4-like">/ Kafka Monitoring </span>
                            </p>
                            <h1>
                                Apache Kafka monitoring & observability to drive engineering
                                productivity
                            </h1>
                            <div className="pr-5 pt-3 pb-2">
                                <p className="hero-description-dark pr-5">
                                    Confidence with Apache Kafka depends on engineers having unified
                                    visibility of real-time data & applications. Or you end up with
                                    whiplash from swivelling between so many screens and{' '}
                                    <b>Kafka monitoring tools. </b>
                                </p>
                            </div>
                            <div className="roll-button">
                                <a
                                    className="bg-red text-white"
                                    href="/guide/everything-need-know-about-kafka-monitoring/"
                                >
                                    <span data-hover="Get the monitoring guide">
                                        Download monitoring guide
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 pt-0 ipad-margin-top">
                            <StaticImage
                                className="img-fluid mx-auto d-block mobile-margin-top-half "
                                quality={100}
                                src="../../resources/images/usecases/monitor/kafka-monitoring-hero-image.svg"
                                placeholder="tracedSVG"
                                alt="Kafka Monitoring"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="bg-cyan mobile-margin-top">
                <div className="container-1 py-5">
                    <div className="text-center">
                        <h2 className="h1-like pb-4 w-90 mx-auto">
                            Monitoring any distributed environment is hard; then there’s Kafka
                            monitoring!
                        </h2>{' '}
                        <p className="w-90 mx-auto">
                            Infinite failure modes can impact the performance & availability of a
                            Kafka streaming app.
                        </p>
                        <p className="w-90 mx-auto">
                            Monitoring your Kafka infrastructure by looking into Replica Fetcher
                            Threads and Disk Write wait times won’t save you if there’s schema
                            drift, incorrect ACLs, maxed-out Quotas or poor partitioning.
                        </p>
                        <p className="w-90 mx-auto">
                            When you have to troubleshoot a Kafka flow, where do you start to figure
                            out what the heck is going on?
                        </p>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-sm-7 col-10 mb-3">
                            <div className="text-center">
                                <h2 className="h1-like">
                                    What is Kafka monitoring? What are the benefits?
                                </h2>
                            </div>
                        </div>
                        <div className="col-sm-9 col-10 mb-3">
                            <div className="text-center">
                                <h2 className="h1-like">
                                    <p>
                                        Apache Kafka is a complex a black box, requiring monitoring
                                        for many services including Schema Registry, Kafka Connect
                                        and real-time flows.
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap">
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5 text-center">
                                    <StaticImage
                                        placeholder="tracedSVG"
                                        quality={100}
                                        src="../../resources/images/usecases/monitor/engineering-productivity.svg"
                                        alt="Higher engineering productivity"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Higher engineering productivity
                                    </h3>
                                    <p className="pt-3 text-center">
                                        A Kafka UI to monitor consumer lag, partitioning, schemas &
                                        more, saving engineering from maintaining multiple tools.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5 text-center">
                                    <StaticImage
                                        src="../../resources/images/usecases/monitor/quality-code.svg"
                                        quality={100}
                                        placeholder="tracedSVG"
                                        alt="Better quality code"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Better quality code
                                    </h3>
                                    <p className="pt-3 text-center">
                                        Inspect data streams with SQL to understand how your
                                        microservice is behaving.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5 pb-4">
                                    <StaticImage
                                        src="../../resources/images/usecases/monitor/high-service-levels.svg"
                                        placeholder="tracedSVG"
                                        alt="Improving Service Levels"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Improving service levels
                                    </h3>
                                    <p className="pt-3 text-center">
                                        Metrics & real-time alerts on Kafka performance & streaming
                                        flows.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="bg-dark mobile-margin-top">
                <div className="container-1 py-5">
                    <div className="text-center">
                        <div className="w-15 mb-4 mobile-w-50 mx-auto">
                            <StaticImage
                                className="img-fluid"
                                placeholder="tracedSVG"
                                src="../../resources/images/clients/playtika/playtika_white.png"
                                alt="Playtika"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-sm-6 col-10">
                            <p className="text-white f-20 lh-35 text-center">
                                “Lenses is critical for us in making our teams productive with Kafka
                                and giving confidence to hundreds of developers.”
                            </p>
                        </div>
                        <div className="col-sm-7 col-10">
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                <p className="f-18 fw-900 text-center text-white mobile-text-center">
                                    {' '}
                                    VP of IT Engineering at Playtika - Ella Vidra
                                </p>
                            </div>
                            <div className="text-center">
                                <a href="/customers/playtika/">
                                    <p className="primary-text f-18 fw-900 text-center pt-2">
                                        Read their story <i className="red-pointer-right"></i>
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-8">
                            <div className="text-center px-2">
                                <h2 className="h1-like">
                                    Best practices for Apache Kafka monitoring
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap pt-5 mt-5 align-items-center mobile-margin-reset">
                        <div className="col-md-5 col-11 ">
                            <h2>
                                Are my real-time data platform, streaming applications and data
                                healthy?
                            </h2>
                            <p className="pr-2">
                                Monitor Kafka infrastructure & application performance from a single
                                role-based and secured Kafka UI. Spinning up full-service visibility
                                helps stay on top of your data and app health.
                            </p>
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={monitor01}
                                large={monitor01}
                                alt="Are my applications healty?"
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 align-items-center ">
                        <div className="col-md-6  col-12">
                            <ModalImage
                                className="pt-2 w-100"
                                small={monitor02}
                                large={monitor02}
                                alt="Alerted for Kafka problems"
                            />
                        </div>
                        <div className="offset-md-1 offset-0"></div>
                        <div className="col-md-5 col-12">
                            <h2>How can I be alerted of any problems in Kafka?</h2>
                            <p>
                                Consumer lag? Under-replicated partitions? Or is your Kafka cluster
                                down? Trigger alerts in real time to your incident management tools
                                including{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.lenses.io/using-lenses/monitor/cloudwatch/"
                                >
                                    AWS Cloudwatch
                                </a>
                                ,{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.lenses.io/using-lenses/monitor/prometheus/"
                                >
                                    Prometheus Alert Manager
                                </a>
                                ,{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.lenses.io/using-lenses/monitor/slack/"
                                >
                                    Slack
                                </a>
                                ,{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.lenses.io/using-lenses/monitor/pagerduty/"
                                >
                                    PagerDuty
                                </a>
                                ,{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.lenses.io/using-lenses/monitor/splunk-webhook/"
                                >
                                    Splunk
                                </a>{' '}
                                or{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.lenses.io/using-lenses/monitor/datadog/"
                                >
                                    DataDog{' '}
                                </a>
                                .
                            </p>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap py-5 align-items-center ">
                        <div className="col-md-5 col-11">
                            <h2>
                                My Kafka applications are all over the place! How can I see how my
                                streams are connected?
                            </h2>
                            <p>
                                View & monitor streaming data pipelines via a Topology view that
                                dynamically shows deployed Kafka Connect connectors and custom
                                microservices connected to your topics.
                            </p>
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={monitor03}
                                large={monitor03}
                                alt="Observe Streams connection"
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 align-items-center ">
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={monitor04}
                                large={monitor04}
                                alt="View events inside Kafka Topics"
                            />
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-5 col-12">
                            <h2>How can I view events inside my Kafka topics?</h2>
                            <p>
                                Troubleshoot by exploring events using SQL via a Kafka GUI or API.
                                Lenses intelligently understands your data regardless of
                                serialization: Avro, Protobuf, JSON, CSV and more.
                            </p>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap pt-5 align-items-center ">
                        <div className="col-md-5 col-11 ">
                            <h2>
                                How can I explore Schemas, Quotas and ACLs to troubleshoot my
                                application?
                            </h2>
                            <p>
                                Get to that root-cause by viewing schemas, Quotas and ACLs all
                                within a secured & unified Kafka UI and API.
                            </p>
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={monitor05}
                                large={monitor05}
                                alt="Explore Schemas"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-sm-5 col-10 mb-3">
                            <div className="text-center">
                                <h2 className="h1-like">Kafka monitoring architecture</h2>
                            </div>
                        </div>
                    </div>

                    <StaticImage
                        className="p-2 w-100"
                        src="../../resources/images/usecases/monitor/kafka-monitoring-architecture.svg"
                        quality={100}
                        placeholder="tracedSVG"
                        alt="Monitoring architecture"
                    />
                </div>
            </section>
            <Spacing />

            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-lg-5 col-md-8 col-12 mobile-text-center">
                            <h2 className="h1-like text-center">
                                What Kafka components should I monitor?
                            </h2>
                        </div>
                        <div className="col-sm-9 col-12 mr-5 mobile-margin-reset">
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="col-sm-3 col-6">
                                    <img src={business_service} alt="business_service" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Business Service
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={application} alt="application" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Application
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={flows} alt="flows" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Flows
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={dataImage} alt="data" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Data
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="col-sm-3 col-6">
                                    <img src={brokers} alt="brokers" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Brokers
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={zookeeper} alt="zookeeper" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Zookeeper
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={schema_registry} alt="schema_registry" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Schema Registry
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={connect} alt="connect" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Connect
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="col-sm-3 col-6">
                                    <img src={system} alt="system" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        System
                                    </p>
                                </div>
                                <div className="col-sm-3 col-6">
                                    <img src={network} alt="network" />
                                    <p className="text-uppercase cera-pro text-center fw-700">
                                        Network
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="bg-usecase-monitor py-5 mobile-margin-top">
                <div className="container-1">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-sm-8 col-12">
                            <p className="f-16 text-center cera-pro text-uppercase text-white">
                                {' '}
                                Case Study
                            </p>
                            <div className="text-center">
                                <StaticImage
                                    src="../../resources/images/usecases/secure/babylon.png"
                                    quality={100}
                                    placeholder="tracedSVG"
                                    alt="Babylon"
                                />
                            </div>
                            <h2 className="fw-900 text-center text-white h1-like w-50 mx-auto mobile-w-100">
                                Kafka monitoring for a healthtech unicorn
                            </h2>

                            <p className="f-20 text-white text-center mb-0">
                                Exploring a universe of events using a simple SQL-like syntax helps
                                Babylon Health engineers to troubleshoot their microservices.
                            </p>

                            <div className="mt-4 text-center py-3">
                                <a
                                    className="primary-text"
                                    href="/videos/babylon-health-dataops-apache-kafka/"
                                >
                                    Watch the video <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1">
                    <div className="d-flex flex-wrap">
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="home-three-items-box border-top-0 rounded-1">
                                <StaticImage
                                    className="w-100 rounded-top-1"
                                    src="../../resources/images/usecases/monitor/guide-thumbnail.png"
                                    placeholder="tracedSVG"
                                    alt="GDPR Compliance Blog"
                                />
                                <div className="py-2 px-3">
                                    <p className="h4-like mb-1">Guide</p>
                                    <div>
                                        <p className="cera-pro d-inline"> The ultimate guide to </p>
                                        <h3 className="f-19 d-inline">Kafka monitoring</h3>{' '}
                                    </div>
                                    <a
                                        className="primary-text home-link-box"
                                        href="/guide/everything-need-know-about-kafka-monitoring/"
                                    >
                                        Download now <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="home-three-items-box border-top-0 rounded-1">
                                <StaticImage
                                    className="w-100 rounded-top-1"
                                    src="../../resources/images/usecases/monitor/case-study-thumbnail.png"
                                    placeholder="tracedSVG"
                                    alt="Kafka Connect Blog"
                                />
                                <div className="py-2 px-3">
                                    <p className="h4-like mb-1">Case study</p>
                                    <div>
                                        <p className="cera-pro d-inline">
                                            Vortexa delivers real-time insights on{' '}
                                        </p>
                                        <h3 className="f-19 d-inline"> AWS MSK</h3>{' '}
                                        <p className="cera-pro d-inline">
                                            with <b> Lenses.io</b>
                                        </p>
                                    </div>
                                    <a
                                        className="primary-text home-link-box"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://aws.amazon.com/blogs/big-data/vortexa-delivers-real-time-insights-on-amazon-msk-with-lenses-io/"
                                    >
                                        Read Case Study <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="home-three-items-box border-top-0 rounded-1">
                                <StaticImage
                                    className="w-100 rounded-top-1"
                                    src="../../resources/images/usecases/monitor/checklist-thumbnail.png"
                                    placeholder="tracedSVG"
                                    alt="Kafka to Splunk Blog"
                                />
                                <div className="py-2 px-3">
                                    <p className="h4-like mb-1">Checklist</p>
                                    <div>
                                        <p className="cera-pro d-inline">
                                            Building an event streaming platform for
                                        </p>
                                        <h3 className="f-19 d-inline">Apache Kafka</h3>{' '}
                                    </div>
                                    <a
                                        className="primary-text home-link-box"
                                        href="/resources/build-a-kafka-data-platform/"
                                    >
                                        Download now <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AboveFooterSection
                text={'Get your DataOps workspace for Apache Kafka'}
                ctaText={"Let's go"}
                ctaLink="/start/"
            />
        </Layout>
    )
}
export default kafkaMonitoring
